import type { AppProps } from 'next/app'
import { ChakraProvider, Link } from '@chakra-ui/react'
import { appWithTranslation } from 'next-i18next'
import Script from 'next/script'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import CookieConsent from 'react-cookie-consent'
// import '@fontsource/inter/400.css'
// import '@fontsource/inter/700.css'

import theme from '../theme'

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      window.gtag('config', 'G-MQD2EQKKQL', {
        page_path: url,
      })
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.on('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ChakraProvider theme={theme}>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-MQD2EQKKQL"
        async={true}
      />
      <Script type="text/javascript" id="ms-clarity">
        {`
        (function(c,l,a,r,i,t,y) {
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "jis70bycsq");
          `}
      </Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-MQD2EQKKQL');
        `}
      </Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
         function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '678074913446233');
fbq('track', 'PageView');
        `}
      </Script>

      <Component {...pageProps} />
      <CookieConsent
        buttonText="Nõustun"
        onAccept={() => {
          router.reload()
        }}
      >
        LeiaCatering kodulehte külastades nõustute küpsiste kasutamisega.{` `}
        <Link
          color="yellow.300"
          href="https://leiacatering.ee/kasutustingimused"
          isExternal
        >
          Loe lähemalt
        </Link>
      </CookieConsent>
    </ChakraProvider>
  )
}
export default appWithTranslation(MyApp)
